@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFlOG829Oofr2wohFbTp9i9WyEJ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9kwMvDQ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nF7OG829Oofr2wohFbTp9iFOQ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9ywIvDQ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9gwQvDQ.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/notosanstc/v26/-nFkOG829Oofr2wohFbTp9i9uwYvDQ.otf) format('opentype');
}
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Noto Sans TC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fa;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#menu-appbar .MuiMenu-paper {
  left: 0 !important;
  top: 92px !important;
  width: 100vw;
  height: calc(100vh - 92px);
  max-width: unset;
  border-top: 2px solid #58457e;
}
#menu-appbar .MuiList-root {
  display: flex;
  flex-direction: column;
  height: 96%;
}
#menu-appbar .MuiMenu-paper .MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px dashed #58457e;
}
#menu-appbar .MuiMenu-paper .MuiMenuItem-root:last-child {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
@layer components {
  * {
    box-sizing: unset;
  }
  [type='text'] {
    @apply !bg-[unset] !ring-0;
  }
  svg.dot {
    box-sizing: unset;
    display: inline-block;
  }
  .inquiry-partner.front-color {
    @apply before:content-[''] before:absolute before:bg-[#3b3156] before:w-full before:h-full before:top-0 before:left-0 before:-z-10 before:bg-opacity-90;
  }
}
